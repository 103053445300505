import React from 'react';

import parse from 'html-react-parser';

import { getImage } from 'gatsby-plugin-image';
import {
  StyledWrapper,
  StyledTitle,
  StyledSubtitle,
  StyledImageWrapper,
  StyledImage,
  StyledContent,
} from './VeganStyles';

const Vegan = ({ data, currentLocale }) => (
  <StyledWrapper backgroundColor={data.backgroundColor}>
    <StyledImageWrapper
      onClick={() => {
        if (data.image.caption) {
          const links = data.image.caption.split('|');
          window.open(
            currentLocale === 'en' && links[1] ? links[1] : links[0],
            '_blank'
          );
        }
      }}
    >
      <StyledImage
        image={getImage(data.image.localFile)}
        alt={data.image.alternativeText ?? 'moisturize'}
      />
    </StyledImageWrapper>
    <StyledContent>
      <StyledTitle>{parse(data.title)}</StyledTitle>
      <StyledSubtitle>{parse(data.text)}</StyledSubtitle>
    </StyledContent>
  </StyledWrapper>
);

export default Vegan;
