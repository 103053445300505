import React from 'react';
import styled from 'styled-components';

const StyledEclipse = styled('svg')`
  width: 180px;
  ${({ theme }) => theme.mq.md} {
    width: 230px;
  }
`;

const Eclipse = () => (
  <StyledEclipse
    width="230"
    height="230"
    viewBox="0 0 230 230"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="115" cy="115" r="115" fill="url(#form_circle)" />
    <defs>
      <linearGradient
        id="form_circle"
        x1="88"
        y1="-14.5"
        x2="135.5"
        y2="237.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F4A9AE" />
        <stop offset="1" stopColor="#F4A9AE" />
      </linearGradient>
    </defs>
  </StyledEclipse>
);

export default Eclipse;
