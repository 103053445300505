import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

export const StyledWrapper = styled('div')`
  width: 100vw;
  min-height: 70vh;
  background-color: ${({ backgroundColor }) => backgroundColor ?? '#FFFFFF'};
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
  ${({ theme }) => theme.mq.xl} {
    min-height: 100vh;
    flex-direction: row;
    align-items: stretch;
  }
`;

export const StyledTitle = styled('h2')`
  font-weight: 600;
  font-size: 3rem;
  ${({ theme }) => theme.mq.md} {
    font-size: 4rem;
  }
  ${({ theme }) => theme.mq.xxxl} {
    font-size: 5rem;
  }
`;

export const StyledSubtitle = styled('span')`
  font-size: 2rem;
  line-height: 2.3rem;
  ${({ theme }) => theme.mq.xxl} {
    font-size: 2.3rem;
    line-height: 2.6rem;
  }
  ${({ theme }) => theme.mq.xxxl} {
    font-size: 2.5rem;
    line-height: 2.8rem;
  }
`;

export const StyledImageWrapper = styled('div')`
  width: 100%;
  ${({ theme }) => theme.mq.xl} {
    width: 50%;
  }
`;

export const StyledImage = styled(GatsbyImage)`
  cursor: pointer;
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 100%;
`;

export const StyledContent = styled('div')`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  padding: 10rem;
  gap: 3rem;
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.mq.xl} {
    width: 50%;
    justify-content: center;
  }
`;
