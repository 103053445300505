import React, { useRef, useEffect } from 'react';
import parse from 'html-react-parser';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { isBrowser } from 'react-device-detect';
import withLocale from 'hoc/withLocale';

import { makeupAnimation } from 'theme/Animations';

import { getImage } from 'gatsby-plugin-image';
import {
  StyledMakeup,
  StyledContainer,
  StyledContentWrapper,
  StyledTitle,
  StyledText,
  StyledImageWrapper,
  StyledImage,
} from './CareForBodyStyles';

const CareForBody = ({ data, currentLocale }) => {
  let container = useRef(null);
  let title = useRef(null);
  let content = useRef(null);

  useEffect(() => {
    if (isBrowser) {
      makeupAnimation(container, title, content);
    }
  }, []);

  return (
    <StyledMakeup
      ref={el => {
        container = el;
      }}
      backgroundColor={data.backgroundColor}
    >
      <StyledContainer>
        <StyledImageWrapper
          onClick={() => {
            if (data.image.caption) {
              const links = data.image.caption.split('|');
              window.open(
                currentLocale === 'en' && links[1] ? links[1] : links[0],
                '_blank'
              );
            }
          }}
        >
          <StyledImage
            image={getImage(data.image.localFile)}
            alt={data.image.alternativeText ?? 'moisturize'}
          />
        </StyledImageWrapper>
        <StyledContentWrapper>
          <StyledTitle
            ref={el => {
              title = el;
            }}
          >
            {parse(data.title)}
          </StyledTitle>
          <StyledText
            ref={el => {
              content = el;
            }}
          >
            {parse(data.text)}
          </StyledText>
        </StyledContentWrapper>
      </StyledContainer>
    </StyledMakeup>
  );
};

export default withLocale(CareForBody);
