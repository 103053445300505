import React from 'react';
import parse from 'html-react-parser';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import {
  StyledWrapper,
  StyledItemWrapper,
  StyledTitle,
  StyledText,
  StyledImageRightWrapper,
  StyledImageLeftWrapper,
} from './HelloGlowStyles';

const HelloGlow = ({ data }) => (
  <StyledWrapper backgroundColor={data.backgroundColor}>
    {data.images && data.images[0] && (
      <StyledImageRightWrapper>
        <GatsbyImage
          image={getImage(data.images[0].localFile)}
          alt={data.images[0].alternativeText ?? 'hands'}
        />
      </StyledImageRightWrapper>
    )}
    {data.images && data.images[1] && (
      <StyledImageLeftWrapper>
        <GatsbyImage
          image={getImage(data.images[1].localFile)}
          alt={data.images[1].alternativeText ?? 'feet'}
        />
      </StyledImageLeftWrapper>
    )}

    <StyledItemWrapper>
      <StyledTitle>{parse(data.title)}</StyledTitle>
      <StyledText>{parse(data.text)}</StyledText>
    </StyledItemWrapper>
  </StyledWrapper>
);

export default HelloGlow;
