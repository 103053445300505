import styled, { css } from 'styled-components';

export const StyledInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 20px;
  ${({ theme }) => theme.mq.smm} {
    margin-bottom: 30px;
  }
  ${({ theme }) => theme.mq.md} {
    margin-bottom: 50px;
  }
`;

export const StyledLabel = styled.label`
  text-align: left;
  color: ${({ theme }) => theme.colors.greenLight};
  font-weight: 600;
  font-size: 1.6rem;
`;

export const StyledBorder = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: ${({ theme, isError }) =>
    isError ? theme.colors.red : theme.colors.greenLight};
  transition: 0.3s;
  ${({ hasContent, isError }) =>
    (hasContent || isError) &&
    css`
      width: 100%;
    `}
`;

export const StyledInput = styled.input`
  border: none;
  border-bottom: 2px solid #a9d0c3;
  padding: 10px 0;
  font-size: 1.6rem;
  line-height: 1.6rem;
  background-color: transparent;
  width: 100%;
  max-width: 100%;
  outline: none;
  &:focus ~ ${StyledBorder} {
    width: 100%;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    background-color: #fff !important;
    box-shadow: 0 0 0px 1000px #fff inset;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  }
`;

export const StyledTextarea = styled.textarea`
  border: none;
  border-bottom: 2px solid #a9d0c3;
  font-size: 1.6rem;
  line-height: 2.4rem;
  padding: 10px 5px;
  width: 100%;
  max-width: 100%;
  outline: none;
  &:focus ~ ${StyledBorder} {
    width: 100%;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    background-color: #fff !important;
    box-shadow: 0 0 0px 1000px #fff inset;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  }
`;

export const StyledError = styled('span')`
  display: inline-block;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.red};
  margin-left: 0.8rem;
`;
