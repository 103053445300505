import React from 'react';
import parse from 'html-react-parser';

import {
  StyledBackground,
  MainWrapper,
  StyledTitle,
  StyledText,
  StyledText2,
} from './NaturalStyles';

const Natural = ({ data }) => (
  <StyledBackground
    Tag="div"
    fluid={data.background.localFile.childImageSharp.fluid}
  >
    <MainWrapper>
      <StyledTitle>{parse(data.title)}</StyledTitle>
      <StyledText>{parse(data.text)}</StyledText>
      <StyledText2>{parse(data.text2)}</StyledText2>
    </MainWrapper>
  </StyledBackground>
);

export default Natural;
