import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

export const StyledMakeup = styled('div')`
  background-color: ${({ backgroundColor }) => backgroundColor ?? '#FFFFFF'};
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 5rem 0 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.mq.lg} {
    padding: 6rem 0 8rem;
  }
  ${({ theme }) => theme.mq.xxxl} {
    padding: 10rem 0 9rem;
  }
`;

export const StyledContainer = styled('div')`
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  z-index: 1;
  align-items: center;
  ${({ theme }) => theme.mq.lg} {
    flex-direction: row;
  }
`;

export const StyledContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: 0 2.5rem;
  order: 2;
  ${({ theme }) => theme.mq.lg} {
    order: 1;
    padding-right: 4rem;
  }
  ${({ theme }) => theme.mq.xl} {
    width: 50%;
  }
  ${({ theme }) => theme.mq.xxxl} {
    padding-right: 7rem;
  }
`;

export const StyledTitle = styled('h2')`
  color: ${({ theme }) => theme.colors.pinkDarkest};
  text-align: left;
  font-weight: 600;
  font-size: 3rem;
  display: block;
  margin-bottom: 3rem;
  ${({ theme }) => theme.mq.xxl} {
    font-size: 4rem;
  }
  ${({ theme }) => theme.mq.xxxl} {
    font-size: 5rem;
  }
`;

export const StyledText = styled('div')`
  font-size: 2rem;
  line-height: 2.3rem;
  text-align: left;
  margin-top: 0;
  margin-bottom: 7rem;
  color: ${({ theme }) => theme.colors.pinkDarkest};
  ${({ theme }) => theme.mq.lg} {
    margin: 0 0 5rem 0;
  }
  ${({ theme }) => theme.mq.xl} {
    font-size: 2.3rem;
    line-height: 2.6rem;
  }
  ${({ theme }) => theme.mq.xxxl} {
    font-size: 2.5rem;
    line-height: 2.8rem;
    margin: 0 0 7rem 0;
  }
`;

export const StyledImageWrapper = styled('div')`
  width: 100%;
  ${({ theme }) => theme.mq.xl} {
    width: 50%;
  }
`;

export const StyledImage = styled(GatsbyImage)`
  cursor: pointer;
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 100%;
`;
