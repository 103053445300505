import styled from 'styled-components';

export const StyledWrapper = styled('div')`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20rem 2rem;
  justify-content: center;
  align-items: center;
  background: ${({ backgroundColor }) => backgroundColor ?? '#FFFFFF'};
  ${({ theme }) => theme.mq.md} {
    padding: 20rem 5rem;
  }
  ${({ theme }) => theme.mq.xl} {
    min-height: 100vh;
    padding: 18rem 10rem;
  }
  ${({ theme }) => theme.mq.xxl} {
    padding: 20rem 15rem;
  }
`;

export const StyledImageRightWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: 8rem;
  width: 12rem;
`;

export const StyledItemWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6rem;
  width: 80%;
  ${({ theme }) => theme.mq.xl} {
    width: 60%;
  }
`;

export const StyledImageLeftWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 8rem;
  width: 12rem;
`;

export const StyledTitle = styled('div')`
  position: relative;
  font-size: 3rem;
  line-height: 3.2rem;
  color: ${({ theme }) => theme.colors.black};
  ${({ theme }) => theme.mq.md} {
    font-size: 3.2rem;
    line-height: 3.4rem;
  }
  ${({ theme }) => theme.mq.lg} {
    font-size: 3.5rem;
    line-height: 3.7rem;
  }
  ${({ theme }) => theme.mq.xl} {
    font-size: 3.8rem;
    line-height: 4rem;
  }
`;

export const StyledText = styled('div')`
  font-size: 2rem;
  line-height: 2.2rem;
  color: ${({ theme }) => theme.colors.greenDark};
  ${({ theme }) => theme.mq.md} {
    font-size: 2.2rem;
    line-height: 2.4rem;
  }
  ${({ theme }) => theme.mq.lg} {
    font-size: 2.4rem;
    line-height: 2.6rem;
  }
  ${({ theme }) => theme.mq.xl} {
    font-size: 2.6rem;
    line-height: 2.8rem;
  }
`;
