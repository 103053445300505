import React from 'react';
import parse from 'html-react-parser';
import { getImage } from 'gatsby-plugin-image';

import {
  StyledBackground,
  StyledContainer,
  StyledWrapper,
  StyledContent,
  StyledTitle,
  StyledParagraph,
  StyledImage,
  StyledImageWrapper,
} from './AboutUsStyles';

const AboutUs = ({ data, currentLocale }) => (
  <StyledBackground
    Tag="div"
    fluid={data.background.localFile.childImageSharp.fluid}
  >
    <StyledContainer>
      <StyledWrapper>
        <StyledContent>
          <StyledTitle>{parse(data.title)}</StyledTitle>
          <StyledParagraph>{parse(data.text)}</StyledParagraph>
          <StyledImageWrapper
            onClick={() => {
              if (data.image.caption) {
                const links = data.image.caption.split('|');
                window.open(
                  currentLocale === 'en' && links[1] ? links[1] : links[0],
                  '_blank'
                );
              }
            }}
          >
            <StyledImage
              image={getImage(data.image.localFile)}
              alt={data.image.alternativeText ?? 'fruit mood'}
            />
          </StyledImageWrapper>
        </StyledContent>
      </StyledWrapper>
    </StyledContainer>
  </StyledBackground>
);

export default AboutUs;
