import styled from 'styled-components';
import { Link } from 'gatsby-plugin-intl';
import BackgroundImage from 'gatsby-background-image';
import { GatsbyImage } from 'gatsby-plugin-image';

export const StyledBackground = styled(BackgroundImage)`
  width: 100%;
  position: relative;
  ${({ theme }) => theme.mq.md} {
    height: 100vh;
  }
`;

export const StyledContainer = styled('div')`
  max-width: 600px;
  height: 100%;
  margin: 0 auto;
  ${({ theme }) => theme.mq.lg} {
    max-width: 950px;
  }
  ${({ theme }) => theme.mq.xxl} {
    max-width: 1400px;
  }
`;

export const StyledWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 6rem 2.5rem 6rem;
  overflow: hidden;

  ${({ theme }) => theme.mq.smm} {
    padding: 8rem 2.5rem 8rem;
  }
  ${({ theme }) => theme.mq.lg} {
    padding: 12rem 2.5rem 12rem;
  }
  ${({ theme }) => theme.mq.xl} {
    padding: 16rem 2.5rem 16rem;
  }
`;

export const StyledContent = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.colors.grey};
  text-align: center;
  width: 80%;
  height: 100%;
  ${({ theme }) => theme.mq.lg} {
    width: 70%;
  }
  ${({ theme }) => theme.mq.xxl} {
    width: 60%;
  }
`;

export const StyledTitle = styled('h2')`
  color: ${({ theme }) => theme.colors.black};
  font-size: 4.5rem;
  margin: 0 0 5rem;
  ${({ theme }) => theme.mq.lg} {
    font-size: 6rem;
    margin: 0 0 8rem;
  }
`;

export const StyledParagraph = styled('div')`
  font-size: 1.6rem;
  line-height: 2rem;
  color: ${({ theme }) => theme.colors.pinkDark};
  ${({ theme }) => theme.mq.lg} {
    font-size: 2rem;
    line-height: 2.4rem;
  }
  ${({ theme }) => theme.mq.xxl} {
    font-size: 2.2rem;
    line-height: 2.6rem;
  }
`;

export const StyledBtn = styled(Link)`
  width: auto;
  padding: 15px;
  text-align: center;
  border-radius: 4px;
  font-size: 1.6rem;
  line-height: 1.6rem;
  font-weight: 500;
  background-color: ${({ theme }) => theme.colors.grey};
  color: ${({ theme }) => theme.colors.white};
  transition: background-color 0.3s;
  &:hover {
    background-color: ${({ theme }) => theme.colors.greyLight};
  }
`;

export const StyledImage = styled(GatsbyImage)`
  cursor: pointer;
  display: block;
`;

export const StyledImageWrapper = styled.div`
  cursor: pointer;
  margin: auto auto 2rem;
  padding-top: 2rem;
`;
