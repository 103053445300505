import React from 'react';
import styled from 'styled-components';
import Logo from '../../atoms/Logo';

const StyledCompanyName = styled('div')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90px;
  ${({ theme }) => theme.mq.md} {
    width: 118px;
  }
`;

const CompanyName = () => (
  <StyledCompanyName>
    <Logo width="118px" />
  </StyledCompanyName>
);

export default CompanyName;
