import styled from 'styled-components';

export const StyledHeroBackground = styled.div`
  background-image: url(${({ url }) => url});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const StyledTitle = styled('div')`
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  padding-bottom: 3rem;
  ${({ theme }) => theme.mq.xl} {
    padding-bottom: 6rem;
  }
`;

export const StyledSubtitle = styled('span')`
  font-size: 3.5rem;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.mq.lg} {
    font-size: 4.5rem;
  }
`;

export const StyledContact = styled('div')`
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0.8rem 5rem;
  ${({ theme }) => theme.mq.lg} {
    display: block;
  }
`;

export const StyledSocial = styled('div')`
  display: none;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 3.6rem 5rem;
  ${({ theme }) => theme.mq.lg} {
    display: block;
  }
`;
