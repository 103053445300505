import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../theme/theme';
import { Desktop, Mobile } from './StyledLogo';

const LogoWrapper = styled.div`
  width: ${({ width }) => width ?? '60rem'};
  @media (max-width: ${`${breakpoints.md}px`}) {
    width: ${({ width }) => width ?? '35rem'};
  }

  img {
    width: ${({ width }) => width ?? '60rem'};
    @media (max-width: ${`${breakpoints.md}px`}) {
      width: ${({ width }) => width ?? '35rem'};
    }
  }
`;

const LogoBig = props => {
  const localData = useStaticQuery(graphql`
    {
      logo: file(relativeDirectory: { eq: "logo" }, name: { eq: "logoBig" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  return (
    <>
      <Desktop>
        <LogoWrapper width={props.width}>
          <img src={localData.logo.childImageSharp.fluid.src} alt="logo" />
        </LogoWrapper>
      </Desktop>
      <Mobile>
        <LogoWrapper width={props.width}>
          <img src={localData.logo.childImageSharp.fluid.src} alt="logo" />
        </LogoWrapper>
      </Mobile>
    </>
  );
};

export default LogoBig;
