export const TemplateEnum = {
  FruitMood: 'TemplateTitleTextLinkImgONas',
  OczyszczanieIPielegnacja: 'TemplateTitleTextBigImageLeftNawilzamy',
  TitleSlider: 'TemplateTitleSliderDesignerskieOpakowania',
  ZadbajOCialo: 'TemplateTitleTextSliderMakijaz',
  HelloGlow: 'TemplateTextSingleImageLeftWyjatkoweFormuly',
  Vegan: 'TemplateListBackgroundAloesKoiNawilza',
  Natural: 'TemplateTitleTextSliderRightOczyszczanieTwarzy',
  ContactForm: 'TemplateContactForm',
};
