import BackgroundImage from 'gatsby-background-image';
import styled from 'styled-components';

export const StyledBackground = styled(BackgroundImage)`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
`;

export const MainWrapper = styled('div')`
  width: 70%;
  ${({ theme }) => theme.mq.md} {
    width: 50%;
  }
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 6rem;
  align-items: center;
  text-align: center;
  overflow: hidden;
`;

export const StyledTitle = styled.h1`
  font-size: 4.5rem;
  font-weight: 200;
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.mq.lg} {
    font-size: 5rem;
  }
  ${({ theme }) => theme.mq.xxl} {
    font-size: 5.5rem;
  }
  strong {
    font-weight: 600;
    font-size: 6rem;
    ${({ theme }) => theme.mq.lg} {
      font-size: 6.5rem;
    }
    ${({ theme }) => theme.mq.xxl} {
      font-size: 7rem;
    }
  }
`;

export const StyledText = styled.div`
  font-size: 1.8rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.mq.lg} {
    font-size: 2rem;
  }
  ${({ theme }) => theme.mq.xxl} {
    font-size: 2.2rem;
  }
`;

export const StyledText2 = styled.div`
  font-size: 1.8rem;
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.mq.lg} {
    font-size: 2rem;
  }
  ${({ theme }) => theme.mq.xxl} {
    font-size: 2.2rem;
  }
`;
